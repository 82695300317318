import React, { useMemo } from 'react';
import {
  Box,
  Typography,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Checkbox
} from '@mui/material';
import trackUse from '@utils/trackUse';
import { logPostHogEvent, getPromotions } from '@utils/index';
import { DealPostType } from '@types';
import FilterFade from '../FilterFade';

type PromotionFilterProps = {
  setPromotionFilters: (value: string[]) => void;
  promotionFilters: string[];
  currentlyShowingDeals: DealPostType[];
  latestDealsLoading: boolean;
};

const PromotionFilter: React.FC<PromotionFilterProps> = ({
  setPromotionFilters,
  promotionFilters,
  currentlyShowingDeals,
  latestDealsLoading
}) => {
  // Retrieve the list of promotions from the deals.
  const promotions = useMemo(
    () => getPromotions(currentlyShowingDeals),
    [currentlyShowingDeals]
  );
  const loading = latestDealsLoading;

  // Toggle the checkbox state and fire analytics events.
  const handleCheckboxToggle = (brand: string) => {
    const deselected = promotionFilters.includes(brand);

    // Analytics events
    logPostHogEvent('promotion-chip', {
      value: `${brand}-${deselected ? 'deselected' : 'selected'}`,
      type: 'ACTION'
    });
    trackUse({
      item: 'promotion-chip',
      value: `${brand}-${deselected ? 'deselected' : 'selected'}`,
      type: 'ACTION'
    });

    const newPromotionFilters = deselected
      ? promotionFilters.filter((b) => b !== brand)
      : [...promotionFilters, brand];

    setPromotionFilters(newPromotionFilters);
  };

  if (loading) {
    return null;
  }

  return (
    <Box>
      <Typography id="promotion-dialog-title" variant="overline" sx={{ mb: 1 }}>
        Promotions
      </Typography>
      <Box
        id="scrollable"
        sx={{
          position: 'relative',
          maxHeight: 180,
          overflow: 'auto'
        }}
      >
        <List disablePadding>
          {promotions.map((promotion) => {
            const checked = promotionFilters.includes(promotion.value);
            return (
              <ListItem key={promotion.value} disablePadding>
                <ListItemButton
                  sx={{
                    padding: '0px'
                  }}
                  onClick={() => handleCheckboxToggle(promotion.value)}
                >
                  <ListItemIcon
                    sx={{
                      minWidth: '40px'
                    }}
                  >
                    <Checkbox
                      checked={checked}
                      color="primary"
                      size="small"
                      disableRipple
                    />
                  </ListItemIcon>
                  <ListItemText
                    primary={
                      <Typography
                        component="span"
                        sx={{ fontSize: '10px', lineHeight: 1.2 }}
                      >
                        {promotion.label}{' '}
                        <Box component="span" sx={{ opacity: 0.6 }}>
                          ({promotion.amount})
                        </Box>
                      </Typography>
                    }
                    // Allow wrapping so that long text breaks before flowing under the icon.
                    primaryTypographyProps={{ noWrap: false }}
                  />
                </ListItemButton>
              </ListItem>
            );
          })}
        </List>
        {/* Sticky fade at the bottom */}
        <FilterFade />
      </Box>
    </Box>
  );
};

export default PromotionFilter;
