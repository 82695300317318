import React from 'react';
import { Drawer, Button, IconButton, Typography } from '@mui/material';
import TuneIcon from '@mui/icons-material/Tune';
import Box from '@mui/material/Box';
import { Close } from '@mui/icons-material';
import { DealPostType } from '@types';
import FilterDrawer from '../FilterDrawer';
import { FilterFunctionsType } from '../Filters/utils';

const MobileFilter = ({
  setDrawerOpen,
  drawerOpen,
  hasFilters,
  resetAll,
  showingStr,
  funcs,
  latestDealsLoading,
  unfilteredDealsWithASIN
}: {
  setDrawerOpen: (open: boolean) => void;
  drawerOpen: boolean;
  hasFilters: boolean;
  resetAll: () => void;
  showingStr: string;
  funcs: FilterFunctionsType;
  latestDealsLoading: boolean;
  unfilteredDealsWithASIN: DealPostType[];
}) => {
  return (
    <Box
      display={{
        xs: 'block',
        md: 'none'
      }}
    >
      <Box display="flex" ml="-5px" justifyContent="end" alignItems="center">
        <Button
          onClick={() => setDrawerOpen(true)}
          startIcon={<TuneIcon />}
          sx={
            hasFilters
              ? {}
              : {
                  mb: 1
                }
          }
          variant="outlined"
          size="small"
        >
          Filter Results
        </Button>
        {hasFilters && (
          <IconButton
            size="small"
            onClick={resetAll}
            sx={{
              ml: '8px'
            }}
          >
            <Close />
          </IconButton>
        )}
      </Box>
      {showingStr && hasFilters ? (
        <Box mb={1}>
          <Typography variant="caption">{showingStr}</Typography>
        </Box>
      ) : null}
      <Drawer
        anchor="right"
        open={drawerOpen}
        onClose={() => setDrawerOpen(false)}
        PaperProps={{
          sx: {
            maxWidth: `450px`
          }
        }}
      >
        <Box mb="24px">
          <Button
            variant="outlined"
            onClick={() => setDrawerOpen(false)}
            startIcon={<Close />}
            sx={{ position: 'absolute', right: '8px', top: '8px' }}
            size="small"
          >
            Close
          </Button>
        </Box>
        <FilterDrawer
          funcs={{
            ...funcs
          }}
          latestDealsLoading={latestDealsLoading}
          currentlyShowingDeals={unfilteredDealsWithASIN}
          showingStr={showingStr}
        />
      </Drawer>
    </Box>
  );
};

export default MobileFilter;
