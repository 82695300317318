import React from 'react';
import { Box } from '@mui/material';
import { useTheme } from '@mui/system';

const FilterFade = () => {
  const theme = useTheme();
  const isDarkMode = theme.palette.mode === 'dark';
  return (
    <Box
      sx={{
        position: 'sticky',
        bottom: 0,
        height: 20,
        background: `linear-gradient(rgba(255,255,255,0), ${
          isDarkMode ? '#121212' : '#fff'
        })`,
        pointerEvents: 'none'
      }}
    />
  );
};

export default FilterFade;
